import {
    ANON_ROLE_UID,
    AnyPcbNode,
    createPcbLayoutNodeExpressionContext,
    createSubjectExpressionContext,
    documentIsPublished,
    ElectricalContextDocumentDataSubsetSubset,
    ElementHelper,
    getElectricalExpressionDependencies,
    getMechanicalExpressionDependencies,
    getProjectExpressionEvaluationContext,
    getProperty,
    ICommentThreadsMap,
    IExpressionEvaluationPcbLayoutNodeContext,
    IExpressionEvaluationSchematicSubjectContext,
    IRoutesMap,
    isExpression,
    MechanicalContextDocumentDataSubset,
    PcbNodesMap,
    PcbRuleSetsMap,
    pcbLayoutRootNodeId as rootNodeId,
    stringifyElectricalString,
    stringifyMechanicalString,
    GetProfileUrlOptions,
    getProfileUrlForDocumentOwner,
} from "@buildwithflux/core";
import {IElementsMap, IVertexMap, LayoutRules, PermissionType} from "@buildwithflux/models";
import {createSelector} from "@reduxjs/toolkit";
import {every, get, keys, set} from "lodash";
import createCachedSelector from "re-reselect";
import {useMemo} from "react";
import {shallowEqual} from "react-redux";

// eslint-disable-next-line boundaries/element-types
import {getActiveServicesContainerBadlyAsServiceLocator} from "../../../../injection/singleton";
import {FluxLogger} from "../../../../modules/storage_engine/connectors/LogConnector";
import {DocumentStorageHelper} from "../../../../modules/storage_engine/helpers/DocumentStorageHelper";
import {getAllBakedNodes} from "../../../../modules/stores/pcb/utils";
import DocumentPatchManager from "../../../../redux/reducers/document/DocumentPatchManager";
import type {IApplicationState} from "../../../../state";
import {AppStatus} from "../../../reducers/app";
import commentThreadsSelectors from "../commentThreads/selectors";
import elementsSelectors from "../elements/selectors";
import pcbLayoutNodesSelectors from "../pcbLayoutNodes/selectors";
import pcbLayoutRulesSelectors from "../pcbLayoutRules/selectors";
import propertiesSelectors from "../properties/selectors";
import routesSelectors from "../routes/selectors";
import verticesSelectors from "../routeVertices/selectors";
import {pickValues} from "@buildwithflux/shared";

// For memoization by ref equality
const emptyArray: string[] = [];
const onlyRootNodeArray: string[] = [rootNodeId];

// QUESTION: why are a bunch of these selecting from state.document instead of
// state.baseDocument? do they belong here?
const selectUid = (state: IApplicationState) => state.document?.uid;
const selectName = (state: IApplicationState) => state.document?.name;
const selectIsLocalComponent = (state: IApplicationState) => !!state.document?.localToDocumentUid;
const selectLocalToDocumentUid = (state: IApplicationState) => state.document?.localToDocumentUid;
const selectDescription = (state: IApplicationState) => state.document?.description;
const selectDescriptionWithFallback = (state: IApplicationState) =>
    state.document?.description || state.documentMeta.baseDocument?.description;
const selectSlug = (state: IApplicationState) => state.document?.slug;
const selectSlugWithFallback = (state: IApplicationState) =>
    state.document?.slug || state.documentMeta.baseDocument?.slug;
const selectTitleWithFallback = (state: IApplicationState) =>
    state.document?.name || state.documentMeta.baseDocument?.name;
const selectOwnerHandle = (state: IApplicationState) => state.document?.owner_handle;
const selectOwnerUid = (state: IApplicationState) => state.document?.owner_uid;
const selectBehaviour = (state: IApplicationState) => state.document?.behaviour;
const selectForkCount = (state: IApplicationState) => state.document?.fork_count;
const selectStarCount = (state: IApplicationState) => state.document?.star_count;
const selectBelongsToPartUid = (state: IApplicationState) => state.document?.belongs_to_part_uid;
const selectElementUids = (state: IApplicationState) => Object.keys(state.document?.elements || {});
const selectControls = (state: IApplicationState) => state.document?.controls;
const selectEnterpriseOwnerUid = (state: IApplicationState) => state.document?.enterprise_owner_uid;
const selectEnterpriseOwnerHandle = (state: IApplicationState) => state.document?.enterprise_owner_handle;
const selectEnterpriseMemberPermissionType = (state: IApplicationState) =>
    state.document?.enterprise_member_permission_type;
const selectOrganizationOwnerIsEnterpriseDefault = (state: IApplicationState) =>
    state.document?.organization_owner_is_enterprise_default;
const selectOrganizationOwnerUid = (state: IApplicationState) => state.document?.organization_owner_uid;
const selectOrganizationMemberPermissionType = (state: IApplicationState) =>
    state.document?.organization_member_permission_type;
const selectNonBranchPointUids = (state: IApplicationState) =>
    Object.keys(state.document?.elements || {}).filter((elementUid) => {
        if (!state.document?.elements?.[elementUid]!.part_version_data_cache) return false;
        return !ElementHelper.isBranchPointElement(state.document?.elements?.[elementUid]!);
    });

// NOTE: must use shallowEqual for memoization
const selectSelectedElementUids = (state: IApplicationState) =>
    (state.document?.selectedObjectUids || emptyArray).filter((elementUid: string) => {
        return (
            !!state.document?.elements?.[elementUid] &&
            !ElementHelper.isBranchPointElement(state.document?.elements[elementUid]!)
        );
    });

// NOTE: must use shallowEqual for memoization
const selectSelectedSymbolUids = (state: IApplicationState) =>
    (state.document?.selectedObjectUids || emptyArray).filter((uid: string) => {
        // Symbols are "virtual subjects" in that they don't have corresponding
        // concrete entities in the document data (they are an abstract concept
        // derived from terminal properties). So we determine what kind of uid
        // it is based on the structure of the uid string, not the presence of
        // the symbol in the document data.
        const elementUid = ElementHelper.parseSymbolUid(uid)?.elementUid;
        if (elementUid) {
            return !!state.document?.elements?.[elementUid];
        }
        return false;
    });

// NOTE: must use shallowEqual for memoization
const selectSelectedElementAndNetUids = (state: IApplicationState) =>
    (state.document?.selectedObjectUids || emptyArray).filter((selectedObjectUid: string) => {
        return (
            (!!state.document?.elements?.[selectedObjectUid] &&
                !ElementHelper.isBranchPointElement(state.document?.elements[selectedObjectUid]!)) ||
            !!state.document?.nets?.[selectedObjectUid]
        );
    });

// NOTE: must use shallowEqual for memoization
const selectSelectedNetUids = (state: IApplicationState) =>
    (state.document?.selectedObjectUids || emptyArray).filter((selectedObjectUid: string) => {
        return !!state.document?.nets?.[selectedObjectUid];
    });

// NOTE: needs to be used with shallow comparison for proper memoization
const selectSelectedPcbNodeUids = (state: IApplicationState) => {
    // TODO: selectors should be pure!
    const {documentService} = getActiveServicesContainerBadlyAsServiceLocator();

    const selectedSubjectUids = state.document?.selectedObjectUids || [];

    return pickValues(getAllBakedNodes(documentService), selectedSubjectUids).map((node) => node.uid);
};

const useReceiveLatestDraftsForPart = (partUid: string) => {
    return (state: IApplicationState) => {
        const draftSubs = state.document?.receiveLatestDraftsForPartUids;
        return draftSubs && draftSubs[partUid];
    };
};

const useReceivingLatestDraftsForParts = (state: IApplicationState) => state.document?.receiveLatestDraftsForPartUids;

const selectRouteUids = (state: IApplicationState) => Object.keys(state.document?.routes || {});
const selectUpdatedAt = (state: IApplicationState) => state.document?.updated_at;
const selectUpdatedBy = (state: IApplicationState) => state.document?.updated_by;
const selectContributorUids = (state: IApplicationState) => state.document?.contributor_uids;
const selectCreatedAt = (state: IApplicationState) => state.document?.created_at;
const selectIsPublished = (state: IApplicationState) => {
    return state.document ? documentIsPublished(state.document) : false;
};
const selectPreviewImage = (state: IApplicationState) => state.document?.preview_image;
const selectForkOfDocumentUid = (state: IApplicationState) => state.document?.fork_of_document_uid;

const selectSelectedPcbNodesSupportRotation = (state: IApplicationState) => {
    const unsupportedTypes = LayoutRules.rotation.unsupportedNodeTypes ?? [];

    const selectedSubjectUids = state.document?.selectedObjectUids ?? [];

    // QUESTION: Does this selector need to subscribe to all baked nodes??
    const {documentService} = getActiveServicesContainerBadlyAsServiceLocator();
    const selectedPcbNodes = pickValues(documentService.snapshot().pcbLayoutNodes, selectedSubjectUids);

    return selectedPcbNodes.some((n) => !unsupportedTypes.includes(n.type));
};

// NOTE: needs to be used with shallow comparison for proper memoization
// QUESTION: but why?
// QUESTION: would this better to return a Set for O(1) lookup?
const selectSelectedObjectUids = createSelector(
    (state: IApplicationState) => state.document,
    (_state: IApplicationState, fallbackToRootWhenEmpty?: boolean) => fallbackToRootWhenEmpty,
    (document, fallbackToRootWhenEmpty) => {
        const result = document?.selectedObjectUids || emptyArray;
        if (fallbackToRootWhenEmpty && result.length === 0) {
            return onlyRootNodeArray;
        }
        return result;
    },
    {
        memoizeOptions: {
            resultEqualityCheck: shallowEqual,
        },
    },
);

// HACK: These two selectors are not selecting from redux... but always picking a number from `DocumentPatchManager
// We could either move the two stacks to redux or a dedicated zustand store later..
const selectUndoStackSize = () => DocumentPatchManager.undoStack.length;
const selectRedoStackSize = () => DocumentPatchManager.redoStack.length;
const selectSimulationTimeStepSize = (state: IApplicationState) => state.document?.simulation?.time_step_size;
const selectSimulationTimeStepSizeUnit = (state: IApplicationState) => state.document?.simulation?.time_step_size_unit;

const selectProjectExpressionContext = (state: IApplicationState) => {
    const documentData = state.document || undefined;

    if (documentData) {
        return getProjectExpressionEvaluationContext(documentData);
    }
};

// HACK: this selector mutates states!!!
const selectAnonRole = (state: IApplicationState) => {
    if (state.document) {
        return DocumentStorageHelper.getOrSetAnonRole(state.document);
    }
};

/**
 * Computes if a document has "advanced" permissions.
 *
 * Advanced permissions means any of:
 *  - For an enterprise document, the document is owned by a non-default organization within the enterprise.
 *  - For an organization document, the document has any permissions set for any user that is not a member of the
 *     organization, including the anonymous user.
 *  - For a non-organization document, at least one user has a permission level that differs from the anonymous user.
 *     This state is accessible if, for example, a user sets their document to private and then adds a specific user
 *     with permissions greater than "none".
 *  - There is at least one pending invite that has been sent by email.
 */
const selectDocumentHasAdvancedPermissions = (state: IApplicationState) => {
    if (state.document) {
        const {document} = state;

        if (document.enterprise_owner_uid && !document.organization_owner_is_enterprise_default) {
            return true;
        }

        if (document.organization_owner_uid) {
            if (
                document.roles[ANON_ROLE_UID] &&
                document.roles[ANON_ROLE_UID].permission_type !== PermissionType.none
            ) {
                return true;
            }
        }

        for (const userUid of Object.keys(document.roles)) {
            if (userUid !== ANON_ROLE_UID && userUid !== document.owner_uid) return true;
        }

        for (const invite of Object.values(document.invites)) {
            if (invite.status === "created") return true;
        }
    }

    return false;
};

/**
 * Selects a subset of the document object from store
 *
 * This is a memoized selector, and it will assemble a new object for the subsets,
 * and it depends on the fields we are passing as input selectors
 *
 * TODO: Later, consider use [createStructureSelector](https://github.com/reduxjs/reselect#createstructuredselectorinputselectors-selectorcreator--createselector)
 *
 * Usage: useSelector(selectMechanicalDocumentSubSet, shallow);
 */
const selectMechanicalDocumentSubSet = createSelector(
    [
        selectUid,
        selectDescription,
        selectName,
        selectOwnerHandle,
        selectSlug,
        propertiesSelectors.selectProperties,
        elementsSelectors.selectElements,
        pcbLayoutNodesSelectors.selectPcbLayoutNodes,
    ],
    (
        uid,
        description,
        name,
        ownerHandle,
        slug,
        properties,
        elements,
        pcbLayoutNodes,
    ): MechanicalContextDocumentDataSubset | undefined => {
        if (!uid || !description || !name || !ownerHandle || !slug || !properties || !elements || !pcbLayoutNodes) {
            return undefined;
        }
        return {
            uid,
            description,
            name,
            slug,
            owner_handle: ownerHandle,
            properties,
            elements,
            pcbLayoutNodes,
        };
    },
);

/**
 * Selects a subset of the document object from store
 *
 * This is a memoized selector, and it will assemble a new object for the subsets,
 * and it depends on the fields we are passing as input selectors
 *
 * TODO: Later, consider use [createStructureSelector](https://github.com/reduxjs/reselect#createstructuredselectorinputselectors-selectorcreator--createselector)
 *
 * Usage: useSelector(selectElectricalDocumentSubSet, shallow);
 */
const selectElectricalDocumentSubSet = createSelector(
    [selectUid, selectDescription, selectName, selectOwnerHandle, selectSlug, propertiesSelectors.selectProperties],
    (uid, description, name, ownerHandle, slug, properties): ElectricalContextDocumentDataSubsetSubset | undefined => {
        if (!uid || !description || !name || !ownerHandle || !slug || !properties) {
            return undefined;
        }
        return {
            uid,
            description,
            name,
            slug,
            owner_handle: ownerHandle,
            properties,
        };
    },
);

/**
 * Given a node's uid and a ruleKey we are interested in, select and derive the evaulated value
 * for that rule.
 *
 * This is a memoized selector, and it depends on the following fields
 * - All pcbLayoutNodes
 * - A subset of document data, selected by `selectMechanicalDocumentSubSet`
 *
 * Usage: useSelector((state) => selectRuleEvaluatedValue(state, uid, ruleValue), shallow);
 *
 * TODO: Can we update this to NOT depend on all pcbLayoutNodes, and narrow down the dependency? The calculation here seems expensive, especially the stringify
 * TODO: Move this to another file, for house-keeping purpose. it's not related to baseDocument per se,
 * but we cant create this in `pcbLayoutNodes/selectors.ts` right now because that will end up circular import
 */
const selectRuleEvaluatedValue = createCachedSelector(
    pcbLayoutNodesSelectors.selectPcbLayoutNodes,
    selectMechanicalDocumentSubSet,
    (_state: IApplicationState, nodeUid: string | undefined) => nodeUid,
    (_state: IApplicationState, _nodeUid: string | undefined, ruleValue: any) => ruleValue,
    (pcbLayoutNodes, document, nodeUid, ruleValue) => {
        if (
            ruleValue !== undefined &&
            pcbLayoutNodes !== undefined &&
            document !== undefined &&
            nodeUid !== undefined
        ) {
            const node = pcbLayoutNodes?.[nodeUid];
            if (typeof ruleValue === "string" && isExpression(ruleValue)) {
                const deps = getMechanicalExpressionDependencies(ruleValue);
                const context = createPcbLayoutNodeExpressionContext({...document, pcbLayoutNodes}, FluxLogger, node);
                const atomicContext: Partial<IExpressionEvaluationPcbLayoutNodeContext> = {};

                deps?.forEach((dep) => {
                    set(atomicContext, dep, get(context, dep));
                });

                return stringifyMechanicalString(ruleValue, atomicContext);
            }
        }
    },
)((_, nodeUid: string | undefined, ruleValue: any) => `${nodeUid}${ruleValue}`);

/**
 * Given an element's uid and a propertyKey (name or uid) we are interested in, select and return
 * the property's value and unit
 *
 * This is a memoized selector, and it depends on the following fields
 * - All elements
 *
 * Usage: useSelector((state) => selectElementPropertyPlainValue(state, uid, propertyUid));
 *
 *
 * TODO: Can we update this to NOT depend on all elements, and narrow down the dependency?
 *
 * TODO: Move this to another file, for house-keeping purpose. it's not related to baseDocument per se,
 * but we cant create this in `elements/selectors.ts` right now because that will end up circular import
 */
const selectElementPropertyValueAndUnit = createSelector(
    [
        elementsSelectors.selectElements,
        (_, elementUid: string) => elementUid,
        (_, _2: string, propertyKey: string) => propertyKey,
    ],
    (elements, elementUid, propertyKey) => {
        const element = elements?.[elementUid];
        const property = element?.properties?.[propertyKey] ?? getProperty(propertyKey, element?.properties ?? {});

        if (property) {
            return {value: property.value, unit: property.unit} as const;
        }
    },
);

/**
 * Given an element's uid and a property uid we are interested in, select and derive the evaulated value
 * for that property.
 *
 * This is a memoized selector, and it depends on the following fields
 * - All elements
 * - All pcbLayoutNodes
 * - The element's property object we are interested
 * - A subset of document data, selected by `selectMechanicalDocumentSubSet`
 *
 * Usage: useSelector((state) => selectElementPropertyEvaluatedValue(state, uid, propertyUid), shallow);
 *
 * TODO: Can we update this to NOT depend on all pcbLayoutNodes NOR all elements, and narrow down the dependency? The calculation here seems expensive, especially the stringify
 * TODO: Move this to another file, for house-keeping purpose. it's not related to baseDocument per se,
 * but we cant create this in `elements/selectors.ts` right now because that will end up circular import
 */
const selectElementPropertyEvaluatedValue = createCachedSelector(
    elementsSelectors.selectElements,
    pcbLayoutNodesSelectors.selectPcbLayoutNodes,
    selectElementPropertyValueAndUnit,
    selectElectricalDocumentSubSet,
    (_, elementUid: string) => elementUid,
    (elements, pcbLayoutNodes, property, document, elementUid) => {
        const element = elements?.[elementUid];
        const linkedPcbLayoutElementNode = pcbLayoutNodes?.[elementUid];

        if (property && pcbLayoutNodes !== undefined && document !== undefined) {
            if (typeof property.value === "string" && isExpression(property.value)) {
                if (element && linkedPcbLayoutElementNode) {
                    const deps = getElectricalExpressionDependencies(property.value);

                    const context = createSubjectExpressionContext(element, document, linkedPcbLayoutElementNode);

                    const atomicContext: Partial<IExpressionEvaluationSchematicSubjectContext> = {};

                    // Only fetching the actual required fields from context
                    deps?.forEach((dep) => {
                        set(atomicContext, dep, get(context, dep));
                    });

                    return stringifyElectricalString(property.value, property.unit, atomicContext);
                }
            }
        }
    },
)((_, elementUid: string, propertyUid: string) => `${elementUid}${propertyUid}`);

export interface ISelectionUidsState {
    all: string[];
    elementUids: string[];
    symbolUids: string[];
    verticeUids: string[];
    routeUids: string[];
    netUids: string[];
    commentThreadUids: string[];
    pcbLayoutNodeUids: string[];
    pcbLayoutRuleSetUids: string[];
}

// QUESTION: how is the perf of this selector? it looks like it could be bad
// TODO: this selector really needs refactoring and testing
const selectSelectionUidsState: (state: IApplicationState) => ISelectionUidsState = createSelector(
    [
        selectSelectedObjectUids,
        elementsSelectors.selectElements,
        selectSelectedSymbolUids,
        routesSelectors.selectRoutes,
        verticesSelectors.selectRouteVertices,
        selectSelectedNetUids,
        commentThreadsSelectors.selectCommentThreads,
        pcbLayoutNodesSelectors.selectPcbLayoutNodes,
        pcbLayoutRulesSelectors.selectPcbLayoutRuleSets,
    ],
    (
        selectionUids: string[],
        documentElements: IElementsMap | undefined,
        documentSymbolUids: string[],
        documentRoutes: IRoutesMap | undefined,
        documentRouteVertices: IVertexMap | undefined,
        documentNetUids: string[],
        documentCommentThreads: ICommentThreadsMap | undefined,
        documentPcbLayoutNodes: PcbNodesMap<AnyPcbNode> | undefined,
        documentPcbLayoutRuleSets: PcbRuleSetsMap | undefined,
    ): ISelectionUidsState => {
        // TODO: selectors should be pure!
        const {documentService} = getActiveServicesContainerBadlyAsServiceLocator();

        // `selectedNodeUids` consists _unique_ uid appears in baked nodes
        // or unbaked nodes
        const allBakedNodes = getAllBakedNodes(documentService);
        const selectedNodeUidSet = new Set<string>();
        selectionUids.forEach((uid) => {
            if (uid in allBakedNodes || (documentPcbLayoutNodes && uid in documentPcbLayoutNodes)) {
                selectedNodeUidSet.add(uid);
            }
        });
        const selectedNodeUids = Array.from(selectedNodeUidSet);

        return {
            all: selectionUids,
            elementUids: pickValues(documentElements || {}, selectionUids).map((element) => element.uid),
            symbolUids: documentSymbolUids,
            verticeUids: DocumentStorageHelper.getSelectedVerticesData(documentRouteVertices || {}, selectionUids).map(
                (vertice) => vertice.id,
            ),
            routeUids: DocumentStorageHelper.getSelectedRoutesData(documentRoutes || {}, selectionUids).map(
                (route) => route!.uid,
            ),
            netUids: documentNetUids,
            commentThreadUids: DocumentStorageHelper.getSelectedCommentThreadsData(
                documentCommentThreads || {},
                selectionUids,
            ).map((commentThread) => commentThread!.uid),
            pcbLayoutNodeUids: selectedNodeUids,
            pcbLayoutRuleSetUids: pickValues(documentPcbLayoutRuleSets || {}, selectionUids).map(
                (pcbLayoutRuleSet) => pcbLayoutRuleSet.uid,
            ),
        };
    },
    {
        memoizeOptions: {
            // because functions like pickValues will return a new array
            equalityCheck: shallowEqual,
            // because result is a new object of new arrays
            resultEqualityCheck: (a, b) => every(keys(a).map((key) => shallowEqual(a[key], b[key]))),
        },
    },
);

const selectSelectedLockedElementUids: (state: IApplicationState) => string[] = createSelector(
    [selectSelectedObjectUids, elementsSelectors.selectElements],
    (selectionUids: string[], documentElements: IElementsMap | undefined): string[] => {
        const lockedElements: string[] = [];
        selectionUids.forEach((uid) => {
            if (documentElements?.[uid]?.locked) {
                lockedElements.push(uid);
            }
        });

        return lockedElements;
    },
    {
        memoizeOptions: {
            // because functions like pickValues will return a new array
            equalityCheck: shallowEqual,
        },
    },
);

const selectIsDocumentLoaded = (state: IApplicationState) => !!state.document;
const selectIsLoading = (state: IApplicationState) => {
    return state.app.appStatus === AppStatus.loadingDocument;
};

const selectIsLockedByInheritance = createCachedSelector(
    (state: IApplicationState) => state,
    (state) => state.document?.pcbLayoutNodes,
    (_state: IApplicationState, nodeUid: string) => nodeUid,
    (state: IApplicationState, pcbLayoutNodes, nodeUid): boolean => {
        if (pcbLayoutNodes?.[nodeUid] === undefined || pcbLayoutNodes[nodeUid]?.parentUid === undefined) return false;
        if (pcbLayoutNodes?.[nodeUid]?.locked) return true;

        return selectIsLockedByInheritance(state, pcbLayoutNodes[nodeUid]!.parentUid);
    },
)((_, parentUid: string) => `${parentUid}`);

const selectIsPcbNodeSelected = createCachedSelector(
    (state: IApplicationState) => selectSelectedObjectUids(state, true),
    (_state: IApplicationState, nodeUid: string) => nodeUid,
    (selectedObjectUids, nodeUid) => {
        return selectedObjectUids?.includes(nodeUid || "");
    },
)((_state: IApplicationState, nodeUid: string) => nodeUid);

const useIsAnySubjectSelected = () => {
    return useMemo(() => (state: IApplicationState) => !!((state.document?.selectedObjectUids?.length || 0) > 0), []);
};

const selectOwnerProfileUrl = createSelector(
    selectOwnerHandle,
    selectEnterpriseOwnerUid,
    selectEnterpriseOwnerHandle,
    selectOrganizationOwnerIsEnterpriseDefault,
    (
        ownerHandle,
        enterpriseOwnerUid,
        enterpriseOwnerHandle,
        organizationOwnerIsEnterpriseDefault,
        options: GetProfileUrlOptions = {},
    ) => {
        if (!ownerHandle) {
            return undefined;
        }

        return getProfileUrlForDocumentOwner(
            {
                owner_handle: ownerHandle,
                enterprise_owner_uid: enterpriseOwnerUid,
                enterprise_owner_handle: enterpriseOwnerHandle,
                organization_owner_is_enterprise_default: organizationOwnerIsEnterpriseDefault,
            },
            options,
        );
    },
);

const baseDocumentSelectors = {
    selectUid,
    selectName,
    selectIsLocalComponent,
    selectLocalToDocumentUid,
    selectDescription,
    selectDescriptionWithFallback,
    selectSlug,
    selectSlugWithFallback,
    selectTitleWithFallback,
    selectOwnerHandle,
    selectOwnerUid,
    selectSelectionUidsState,
    selectSelectedObjectUids,
    selectSelectedElementUids,
    selectSelectedSymbolUids,
    selectSelectedElementAndNetUids,
    selectSelectedNetUids,
    selectSelectedLockedElementUids,
    selectSelectedPcbNodeUids,
    selectSelectedPcbNodesSupportRotation,
    selectBehaviour,
    selectForkCount,
    selectStarCount,
    selectBelongsToPartUid,
    selectElementUids,
    selectControls,
    selectNonBranchPointUids,
    selectRouteUids,
    selectUpdatedAt,
    selectUpdatedBy,
    selectContributorUids,
    selectCreatedAt,
    selectIsPublished,
    selectPreviewImage,
    selectForkOfDocumentUid,
    selectUndoStackSize,
    selectRedoStackSize,
    selectAnonRole,
    selectDocumentHasAdvancedPermissions,
    selectSimulationTimeStepSize,
    selectSimulationTimeStepSizeUnit,
    selectIsDocumentLoaded,
    selectIsLoading,
    useReceiveLatestDraftsForPart,
    useReceivingLatestDraftsForParts,
    useIsAnySubjectSelected,
    selectProjectExpressionContext,
    selectMechanicalDocumentSubSet,
    selectRuleEvaluatedValue,
    selectElementPropertyEvaluatedValue,
    selectIsPcbNodeSelected,
    selectEnterpriseOwnerUid,
    selectEnterpriseOwnerHandle,
    selectEnterpriseMemberPermissionType,
    selectOrganizationOwnerIsEnterpriseDefault,
    selectOrganizationOwnerUid,
    selectOrganizationMemberPermissionType,
    selectIsLockedByInheritance,
    selectOwnerProfileUrl,
};

export default baseDocumentSelectors;
